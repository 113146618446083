import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'


const TagRoute = (props) => {
  const { data: { allMarkdownRemark: { edges: posts, totalCount } }, pageContext: { tag } } = props

  const postLinks = posts.map(post => (
    <li key={post.node.fields.slug}>
      <Link className='btn' to={post.node.fields.slug}>
        {post.node.frontmatter.title}
      </Link>
    </li>
  ))

  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } containing tag: “${tag}”`



  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.pl/tagi/'
   });

  return (
    <Layout>
      <section className='section'>
        <Helmet htmlAttributes={{ lang : 'en-US', dir:'ltr' }} title={`${tag} | e-multicontent`}>
        <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className='container content'>
          <div className='columns'>
            <div
              className='column is-10 is-offset-1'
              style={{ marginBottom: '6rem' }}
            >
              <h1 className='title is-size-3 is-bold-light' style={{marginTop:'50px'}}>{tag}</h1>
              <h2 className='title is-size-3 is-bold-light' style={{marginTop:'50px'}}>{tagHeader}</h2>

              <br />
              <ul className='taglist'>{postLinks}</ul>
              <p>
                <Link className='btnblog' to='/tags/'>See all tags</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
